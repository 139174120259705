import './App.css';
import ContactUs from './ContactUs';

function App() {
  return (
    <div className="App">
      <ContactUs />
    </div>
  );
}

export default App;